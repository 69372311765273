import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { SpinnerService } from '../services/spinner/spinner.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: 'progress-spinner.component.html',
  styleUrls: ['progress-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ProgressSpinnerComponent implements OnDestroy, AfterContentInit {
  public showSpinner = false;
  private unsubscribe: Subscription[] = [];
  constructor(
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  ngAfterContentInit() {
    this.unsubscribe.push(
      this.spinnerService.visibility.subscribe((spin) => {
        this.showSpinner = spin;
        this.cdRef.detectChanges();
      })
    );
  }
}

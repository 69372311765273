<igx-toast #toasttype>
  @if ( dataToast){
  <div [ngClass]="dataToast.cssClass ? 'customToast ' + dataToast.cssClass  : 'customToast' ">
    <div class="wrapInfo">
      @if (title) {
      <p class="titleToast">
        {{ title }}
      </p>
      }
      @if (content) {
      <div class="contentToast">
        <div [innerHtml]="content"></div>
      </div>
      }
    </div>
    <button class="btnCloseToast" (click)="closeToast()" [attr.aria-label]="('udata' | translate) + ' ' + ('Close' | translate)">
      <img class="" src="{{closeImage}}" alt="{{'udata' | translate}} {{'close' | translate}}">
    </button>
  </div>
  }
</igx-toast>

import { HttpInterceptorFn } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen } from 'rxjs/operators';
import { inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../services/spinner/spinner.service';
import { ToastService } from '../services/toast/toast.service';
import { AlertService } from '../services/alert/alert.service';

export const retryCount = 1;
export const retryWaitMilliSeconds = 1000;

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const translateService = inject(TranslateService);
  const spinnerService = inject(SpinnerService);
  const toastService = inject(ToastService);
  const alertService = inject(AlertService);
  return next(request)
    .pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (
              count <= retryCount &&
              (error.status === 0 || (error.status === 400 && !error.error))
            ) {
              return of(error);
            }
            return throwError(() => error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    )
    .pipe(
      catchError((err) => {
        spinnerService.hide();
        if (err.status === 304) return;
        if ([401, 403].indexOf(err.status) !== -1) {
          alertService.showError(
            'Error',
            "Vous n'avez pas les droits nécessaires"
          );
        } else if (err.status !== 0) {
        }

        const error = JSON.stringify(err);

        let erreur = err.error?.error ? err.error.error : err.error;
        if (erreur instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            erreur = reader.result;
          };
          reader.readAsText(erreur);
        } else {
          if (err.status === 504) {
            toastService.showError(
              translateService.instant('Timeout Error'),
              ''
            );
          } else if (environment.production && err.status === 500) {
            toastService.showError(
              translateService.instant('Internal Error'),
              ''
            );
          } else if (environment.production && err.status === 400) {
            toastService.showError(translateService.instant('Error'), erreur);
          } else if (environment.production && err.status === 408) {
            toastService.showError(
              translateService.instant('Error'),
              translateService.instant('Error.Timeout.408')
            );
          } else if (
            environment.production &&
            err.status !== 0 &&
            err.status !== 401
          ) {
            toastService.showError(translateService.instant('Error'), '');
          } else if (!environment.production) {
            toastService.showError('Erreur', error);
          }
        }
        return throwError(() => error);
      }).bind(this)
    );
};

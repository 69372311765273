import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private translateService: TranslateService) {}

  show(message) {
    Swal.fire({
      text: this.translateService.instant(message),
      heightAuto: false
    });
  }

  showTitle(title, message) {
    Swal.fire({
      title: title,
      text: this.translateService.instant(message),
      heightAuto: false
    });
  }

  showHtml(title, message) {
    Swal.fire({
      title: this.translateService.instant(title),
      html: this.translateService.instant(message),
      heightAuto: false
    });
  }

  showError(title, message) {
    Swal.fire({
      title: 'Erreur',
      text: this.translateService.instant(message),
      icon: 'error',
      heightAuto: false
    });
  }

  askQuestionWithRange(title, text, inputAttributes, inputValue, html) {
    return new Promise((resolve, reject) => {
      resolve(
        Swal.fire({
          html: html,
          title: title,
          text: text,
          input: 'range',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#117185',
          cancelButtonColor: '#63c3d7',
          inputAttributes: inputAttributes,
          inputValue: inputValue,
          confirmButtonText: this.translateService.instant('Yes'),
          cancelButtonText: this.translateService.instant('No'),
          heightAuto: false,
          inputValidator: (value) => {
            if (!value) {
              return 'Le champ ne peut être vide';
            }
          },
          didOpen: () => {
            const inputRange = Swal.getInput();
            const inputNumber =
              Swal.getHtmlContainer().querySelector('#range-value');

            inputRange.addEventListener('input', () => {
              inputNumber['value'] = inputRange.value;
            });

            inputNumber.addEventListener('change', () => {
              if (
                inputNumber['value'] <= +inputRange.max &&
                inputNumber['value'] >= 0
              )
                inputRange.value = inputNumber['value'];
              else if (inputNumber['value'] > +inputRange.max)
                inputRange.value = inputRange.max;
              inputRange.nextElementSibling.textContent = inputRange.value;
            });
          }
        })
      );
    });
  }

  askQuestion(
    title,
    text,
    input?,
    icon?,
    value?,
    confirmButtonText?,
    cancelButtonText?
  ) {
    return new Promise((resolve, reject) => {
      resolve(
        Swal.fire({
          title: this.translateService.instant(title),
          text: this.translateService.instant(text),
          input: input, // 'text'or 'number'
          inputValue: value ? value : '',
          icon: icon ? icon : 'warning',
          showCancelButton: true,
          confirmButtonColor: '#117185',
          cancelButtonColor: '#63c3d7',
          confirmButtonText: confirmButtonText
            ? this.translateService.instant(confirmButtonText)
            : this.translateService.instant('Yes'),
          cancelButtonText: cancelButtonText
            ? this.translateService.instant(cancelButtonText)
            : this.translateService.instant('No'),
          heightAuto: false,
          backdrop: false,
          inputValidator: (value) => {
            if (!value) {
              return 'The field cannot be empty';
            }
          }
        })
      );
    });
  }

  ShowQuestion(
    title: string,
    text: string,
    cancelButton: string = null,
    confirmButton: string = null,
    iconText: SweetAlertIcon = null
  ) {
    let icons: SweetAlertIcon = null;
    if (iconText !== null) icons = iconText;
    let cancelText = this.translateService.instant('No');
    let confirmText = this.translateService.instant('Yes');
    if (cancelButton) cancelText = this.translateService.instant(cancelButton);
    if (confirmButton)
      confirmText = this.translateService.instant(confirmButton);
    return new Promise((resolve, reject) => {
      resolve(
        Swal.fire({
          title: this.translateService.instant(title),
          text: this.translateService.instant(text),
          icon: icons,
          showCancelButton: true,
          cancelButtonColor: '#1DA54D',
          confirmButtonColor: '#E5646C',
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
          customClass: {
            confirmButton: 'swal2Confirm',
            cancelButton: 'swal2cancel'
          },
          buttonsStyling: true,
          reverseButtons: true
        })
      );
    });
  }
}

import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

export function JwtInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const authenticationService = inject(AuthenticationService);
  const jwt = authenticationService.getJwt();

  if (!jwt || jwt === 'null' || jwt === 'undefined') {
    request = request.clone();
  } else {
    request = request.clone(
      {
        setHeaders: {
          Authorization: `Bearer ${jwt}`
        }
      }
      // { withCredentials: true }
    );
  }
  return next(request);
}

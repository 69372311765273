import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn
} from '@angular/router';
import { Subscription } from 'rxjs';
import { Role } from '../enum/role';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
class PermissionsService implements OnDestroy {
  private unsubscribe: Subscription[] = [];

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let role = null;
    let clientId = null;
    if (!this.authenticationService.isJwtExpired()) {
      role = this.authenticationService.getRole();
      clientId = this.authenticationService.getClientId();
    }

    if (
      this.authenticationService.getJwt() === '' &&
      route.data.roles !== Role.None
    ) {
      this.authenticationService.logout();
      this.router.navigate(['/']);
      return false;
    } else if (
      this.authenticationService.getJwt() === '' &&
      route.data.roles === Role.None
    )
      return true;

    if (clientId) {
      if (route.data.roles === Role.None) {
        this.router.navigate(['/dashboard']);
        return true;
      }
      if (route.data.clientId && route.data.clientId.indexOf(clientId) === -1) {
        this.router.navigate['/'];
        return false;
      }
      return true;
    }
    if (route.routeConfig.path === '') {
      return true;
    }

    this.authenticationService.logout();
    return false;
  }
}
export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionsService).canActivate(next, state);
};

import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { Role } from './enum/role';
import { ClientId } from './enum/clientId';

export const routes: Routes = [
  {
    path: 'callback',
    loadComponent: () =>
      import('./oauth/callback/authCallback.component').then(
        (mod) => mod.AuthCallbackComponent
      ),
    canActivate: [AuthGuard],
    data: { roles: Role.None }
  },
  {
    path: '',
    loadComponent: () =>
      import('./login/login.component').then((mod) => mod.LoginComponent),
    canActivate: [AuthGuard],
    data: { roles: Role.None }
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./layout/layout.component').then((mod) => mod.LayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./home/home.component').then((mod) => mod.HomeComponent),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'work-plan',
        loadComponent: () =>
          import('./workPlan/workPlan.component').then(
            (mod) => mod.WorkPlanComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'resident/:id/intervention',
        loadComponent: () =>
          import('./resident/intervention/residentIntervention.component').then(
            (mod) => mod.ResidentInterventionComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'resident/:id/accident',
        loadComponent: () =>
          import('./resident/accident/accident.component').then(
            (mod) => mod.AccidentComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'resident/:id/notes',
        loadComponent: () =>
          import('./resident/notes/notes.component').then(
            (mod) => mod.NotesComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'resident/:id',
        loadComponent: () =>
          import('./resident/detail/residentDetail.component').then(
            (mod) => mod.ResidentDetailComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'resident',
        loadComponent: () =>
          import('./resident/resident.component').then(
            (mod) => mod.ResidentComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'notification',
        loadComponent: () =>
          import('./notification/notification.component').then(
            (mod) => mod.NotificationComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      },
      {
        path: 'intervention/:id',
        loadComponent: () =>
          import('./intervention/intervention.component').then(
            (mod) => mod.InterventionComponent
          ),
        canActivate: [AuthGuard],
        data: { clientId: [ClientId.Pabui, ClientId.Pabui2] }
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

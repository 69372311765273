import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../services/toast/toast.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  HorizontalAlignment,
  IgxToastComponent,
  IgxToastModule,
  VerticalAlignment
} from 'igniteui-angular';

@Component({
  selector: 'app-toast',
  templateUrl: 'toastShow.component.html',
  styleUrls: ['toastShow.component.scss'],
  standalone: true,
  imports: [IgxToastModule, CommonModule, TranslateModule]
})
export class ToastShowComponent implements OnDestroy, AfterViewInit {
  public showProgressBar = true;
  @ViewChild('toasttype')
  private toastObj: IgxToastComponent;
  public dataToast = null;
  public title: string;
  public content: string;
  private unsubscribe: Subscription[] = [];
  public closeImage = '../assets/img/close.webp';
  constructor(private toastService: ToastService) {}

  ngOnDestroy() {
    this.unsubscribe.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit() {
    this.toastObj.positionSettings.verticalDirection = VerticalAlignment.Top;
    this.toastObj.positionSettings.horizontalDirection =
      HorizontalAlignment.Right;

    this.unsubscribe.push(
      this.toastService.visibility.subscribe((val) => {
        if (val) {
          this.title = val[0].title.toString();
          this.content = val[0].content.toString();
          this.dataToast = val[0];
          if (this.dataToast.cssClass.includes('info'))
            this.closeImage = '../assets/img/close_gray.webp';
          this.toastObj.open();
        }
      })
    );
  }

  closeToast() {
    this.toastObj.close();
  }

  onBeforeOpen(e) {
    if (1 === this.toastObj.element.childElementCount) {
      e.cancel = true;
    } else {
      e.cancel = false;
    }
  }
}

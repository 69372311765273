import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerComponent } from './spinner/progress-spinner.component';
import { ToastShowComponent } from './spinner/toastShow.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { changei18n } from 'igniteui-angular';
import { IgxResourceStringsFR } from 'igniteui-angular-i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterModule, ProgressSpinnerComponent, ToastShowComponent]
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private location: Location
  ) {}

  ngOnInit(): void {
    let lang = '';
    const aspNetCulture = this.cookieService.get('.AspNetCore.Culture');

    if (aspNetCulture) {
      lang = aspNetCulture.substring(2, 4);
      if (lang !== 'en' && lang !== 'fr') lang = 'fr';
    } else {
      this.translateService.addLangs(['fr', 'en']);
      this.translateService.setDefaultLang('fr');
    }
    this.translateService.use(lang);

    this.cookieService.set(
      '.AspNetCore.Culture',
      'c=' + lang + '|uic=' + lang,
      {
        expires: 7,
        path: '/',
        domain: null,
        secure: true,
        sameSite: 'Strict'
      }
    );
    
    this.translateService.stream('a').subscribe({
      next: (val) => {
        if (this.translateService.currentLang === 'fr')
          changei18n(IgxResourceStringsFR);
        else changei18n({});
      }
    });
  }
}
